import * as React from 'react';
import Layout from '../../components/layout/layout';
import SEO from '../../components/seo';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { PostSummary } from '../../components/post-summary';

const Careers = ({ location, data }) => {
  const open_graph_image = data.sharing.childImageSharp
    ? data.sharing.childImageSharp.resize
    : null;

  return (
    <Layout>
      <SEO title="All GrowSF Careers" image={open_graph_image} pathname={location.pathname} />
      <div className="mx-auto max-w-7xl px-5 mt-10 sm:mt-14 sm:px-6">
        <div className="text-center">
          <h1 className="font-extrabold text-4xl sm:text-5xl md:text-6xl text-gray-900">
            GrowSF Careers
          </h1>
          <h2 className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Work with GrowSF to make San Francisco an even better place to live!
          </h2>
        </div>
      </div>
      <div className="max-w-5xl mx-auto pt-8 pb-16 px-5 sm:px-6 lg:px-8">{careersList(data)}</div>
    </Layout>
  );
};

function careersList(data) {
  if (!data.allMdx.nodes.length) {
    return (
      <p className="text-center prose prose-blue sm:prose-lg mx-auto">
        Sorry, there are no open positions at this time. Please check back later!
      </p>
    );
  }
  return (
    <ul className="sm:space-y-12 sm:space-y-0 sm:-mt-4 lg:gap-x-8 lg:space-y-0">
      {data.allMdx.nodes.map((node) => (
        <PostSummary
          key={node.id}
          href={`/${node.slug}`}
          image={node.frontmatter.image ? getImage(node.frontmatter.image.childImageSharp) : ''}
          image_alt={node.frontmatter.image_alt}
          title={node.frontmatter.title}
          summary={node.summary}
          link_text="Learn More"
        />
      ))}
    </ul>
  );
}

export const query = graphql`
  query {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { post_type: { eq: "career" } } }
    ) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          image: featured_image {
            childImageSharp {
              gatsbyImageData
            }
          }
          image_alt: featured_image_alt
        }
        id
        slug
        summary: excerpt(pruneLength: 200)
      }
    }
    sharing: file(relativePath: { eq: "careers/growsf-careers-og.png" }) {
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
  }
`;

export default Careers;
